<template>
  <div>
    <v-breadcrumbs :items="[
      { text: 'Solutions', exact: true, to: { name: 'solutions' } },
      { text: 'Fitness Engine', disabled: true },
    ]" />
    <div :class="`my-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Fitness apps, platforms, API &amp; Engine</h1>
      <p>
        Embed and connect our fitness engine as a service to instantly add Strava, Fitbit, Garmin, Polar, and COROS integrations to any product! We can quickly build and release a customized fitness apps and platform based on our proven technology stack.
      </p>
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" class="text-center align-center pa-4">
          <v-icon size="50" color="grey">fa-mobile</v-icon>
          <h3 class="my-4">iPhone and Android apps</h3>
          <p>Customized iPhone and Android fitness apps in your own app store account.</p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" class="text-center align-center pa-4">
          <v-icon size="50" color="grey">fa fa-desktop</v-icon>
          <h3 class="my-4">Web Platforms</h3>
          <p>Customized web platforms. Your branding and your domain!</p>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card :elevation="1" class="text-center align-center pa-4">
          <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
          <h3 class="my-4">9+ Fitness Device Integrations</h3>
          <p>Instant integration with all popular fitness apps and watches.</p>
        </v-card>
      </v-col>
    </v-row>
    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h3 class="title">Add integration with all major fitness trackers into any product</h3>
          <SectionIntegrations />
        </v-col>
      </v-row>
    </v-container>
    </div>

    <div :class="`mt-10 text-center ${$vuetify.breakpoint.xs?'':'indent'}`">
      <h1>Discover our key features</h1>
      <p>
      </p>
    </div>
    <v-container class="features">
      <v-row>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-strava</v-icon>
          <h4>Auto-sync with Garmin, Strava, Fitbit, Polar and more</h4>
          <p>
            Effortless add integration with Garmin, Polar, Suunto, Fitbit or COROS devices and the Strava app to any product or service using our fitness-engine-as-a-service! 
          </p>

          <p>
            Next to our native integrations we also support manual entry or upload of any other gpx file.  
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-swimmer fa-flip-horizontal</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-bicycle</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-running</v-icon>
          <h4>All sports and combinations</h4>
          <p>
            Our engine supports all popular sports.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Routes and maps</h4>
          <p>
            Gain access to the exact courses for all synced activities to enable a host of different use cases. We provide both raw data and simplified routes for easy processing.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Challenge Integrations</h4>
          <p>
            Optionally you can also integrate (a subset of) our <router-link to="/features">challenge features</router-link> to easily include badges and challenges into any product or app.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>White-label and SSO integrations</h4>
          <p> 
            We can offer our entire platform as a white-label solution with custom branding. One step further is to enable Single Sign-On (SSO) for an effortless integration with any identity platform.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>API integrations</h4>
          <p> 
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. 
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Custom Pricing Available</h4>
          <p> 
            Our flexible pricing is based on the service levels and implemented features and scales with API usage. <router-link to="/contact">Contact us</router-link> to discuss how our engine can power your product.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <div class="green white--text py-6 py-md-12">
      <div class="container text-center">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Get in touch</h2>
            <h1 class="subtitle">Effortless integration with all major fitness trackers!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mb-4" color="white" href="/contact">Contact Us</v-btn>
              <v-btn class="ml-4 mb-4" color="white" outlined href="/request-demo">Request Free Demo</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>

  </div>
</template>


<script>
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import siteData from '@/data/site.json'

export default {
  name: "Contact",
  components: {
    SectionIntegrations,
    SectionCustomers,
  },
  props: {
  },
  data: function() {
    return {
      siteData: siteData,
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }
  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>